<template>

    <Head>
        <title>{{page.news_show.meta_title}}</title>
        <meta  name="description" :content="page.news_show.meta_description">
    </Head>

    <Header :menu_items="menu_items" :page="page" :editable="editable" :website="website"/>

    <section class="text-gray-600 body-font section-text">
        <div class="bg-white  dark:bg-black dark:text-white">
            <div>
                <main class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 page-ckeditor">
                    <div class="border-b border-gray-200 pt-6 pb-3">

                        <nav class="flex" aria-label="Breadcrumb">
                            <button @click.passive="back" v-if="backExists()"
                                    class=" text-sm font-medium text-gray-700 hover:text-gray-900 border-gray-300 border rounded p-1.5 pl-2 pr-3 mr-2 float-left">
                                <span class="sr-only">Terug naar overzicht</span>
                                <span class="whitespace-nowrap text-xs sm:text-sm  dark:text-gray-200 dark:hover:text-white">
                                    <CustomSvg :name="'chevron-left'" color="blue" customClass="w-3 mt-0.5 float-left mr-2 dark:fill-gray-300"></CustomSvg>
                                    Terug
                                        </span>
                            </button>
                            <ol class="inline-flex items-center space-x-1 md:space-x-3 ml-3">
                                <li class="inline-flex items-center">
                                    <jet-nav-link href="/"
                                                  class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <svg class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                                        </svg>
                                    </jet-nav-link>
                                </li>

                                <li>
                                    <div class="flex items-center">
                                        <svg class="w-auto h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd"
                                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                  clip-rule="evenodd"></path>
                                        </svg>
                                        <jet-nav-link :href="'/'+page.page.url"
                                                      class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">
                                            {{ page.page.name }}
                                        </jet-nav-link>
                                    </div>
                                </li>

                                <li>
                                    <div class="flex items-center">
                                        <svg class="w-auto h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd"
                                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                  clip-rule="evenodd"></path>
                                        </svg>
                                        <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400 h-5 overflow-hidden">
                                             {{ page.news_show.title.substring(0, 100) }}<span v-if="page.news_show.title.length > 100">...</span>
                                        </span>
                                    </div>
                                </li>
                            </ol>
                        </nav>

                        <div class="clear-both"></div>

                        <div class="flex flex-wrap px-1 mb-10">
                            <div class=" w-full sm:w-1/2 pt-5 cms-content">
                                <h1 class="text-3xl font-bold text-gray-900 dark:text-white">{{ page.news_show.title }}</h1>
                                <content v-if="page.news_show.lijsttekst.length > 500">
                                    <div class=" mb-3" v-html="page.news_show.lijsttekst"></div>
                                </content>
                                <content v-else>
                                    <div class="italic mb-3" v-html="page.news_show.lijsttekst"></div>

                                    <div v-for="(content2) in page.news_show.content">
                                        <div v-for="(content,type) in content2">
                                            <div v-if="type=='text'" class="my-3"  v-html="content.text"></div>
                                            <img v-else-if="type=='img'" class="my-3" :src="'https://cdn.bewinkel.com/'+content.img"  :alt="content.title" />
                                            <div v-else class="text-red-500">
                                               {{type}}: {{content}}
                                            </div>
                                        </div>
                                    </div>
                                </content>


                            </div>
                            <div class="sm:w-1/2 w-full pt-10 md:pl-20 ">
                                <img :src="'https://cdn.bewinkel.com/600x300a/'+page.news_show.image" :alt="page.news_show.title"
                                     class="w-full h-auto  object-center rounded rounded-xl"/>

                                <h3 class="mt-10">Gerelateerde producten</h3>
                                <div class=" border-t mx-auto max-w-5xl lg:max-w-7xl  clear-both pt-1.5">

                                    <div
                                        class="mt-6 grid grid-cols-2 gap-y-6 gap-x-4 sm:grid-cols-3  lg:grid-cols-2 xl:gap-x-6">
                                        <div v-for="(product,index) in page.news_show.products" :key="product.id"
                                             class=" group relative border-gray-100 rounded-2xl p-0 sm:p-3 cursor-pointer
                          hover:border-gray-400
                          hover:scale-110 bg-white
                        transition ease-in-out delay-150
                        text-gray-700 dark:text-white dark:bg-gray-700
                          ">

                                            <div class="p-0.5 px-2 absolute right-0 text-white z-10 bg-white h-6 rounded mr-1"  @click="addFavourite(product.variant_id)">
                                                <CustomSvg name="favourite-true"  customClass="fill-blue-800 pr-2" v-if="isFavourite(product.variant_id)"></CustomSvg>
                                                <CustomSvg name="favourite-false"  customClass="fill-blue-800 pr-2" v-if="!isFavourite(product.variant_id)"></CustomSvg>
                                            </div>
                                            <jet-nav-link :href="product.product_url" class="!no-underline">
                  <span v-if="product.price_percentage!==0 && product.price_percentage!=='0'" class="bg-green-700 rounded p-0.5 px-2 absolute text-white z-10">
                        {{ product.price_percentage }} %
                    </span>
                                                <div class="group relative mx-auto w-full rounded-lg dark:bg-white p-1">
                                                    <div class="hero container max-w-screen-lg mx-auto  h-44 w-full flex items-center max-w" :ref="'imgBox' + index" :id="'imgBox' + index">
                                                        <img :src="product.image" class="max-h-44 mx-auto"
                                                             :loading="index <9 ? 'eager' :'lazy'"
                                                             :alt="product.name"/>

                                                    </div>
                                                </div>
                                                <h3 class="text-xs sm:text-sm  no-underline h-24 mt-3">
                                                    {{ product.name.substring(0, 80) }}<span v-if="product.name.length > 80">...</span>
                                                </h3>
                                                <div v-if="Object.keys(product.webshops).length> 1" class="text-xs font-light float-left mt-5">
                                                    {{ Object.keys(product.webshops).length }} prijzen
                                                </div>
                                                <div class="text-sm font-medium float-right text-sm text-xl font-bold mt-2">
                                                    &euro; {{ product.price_human }}
                                                </div>

                                            </jet-nav-link>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                </main>
            </div>
        </div>
    </section>

    <Footer  :menu_items="menu_items" :page="page"  :website="website">
        <template #content_website_footer_left>
            <slot name="content_website_footer_left">
            </slot>
        </template>
        <template #content_website_footer_center>
            <slot name="content_website_footer_center">
            </slot>
        </template>
        <template #content_website_footer_right>
            <slot name="content_website_footer_right">
            </slot>
        </template>
    </Footer>
</template>


<script>

import Header from '@/Pages/Admin/Page/Templates/Partials/Header.vue';
import Footer from '@/Pages/Admin/Page/Templates/Partials/Footer.vue';
import JetNavLink from "@/Jetstream/NavLink.vue";
import { Head } from '@inertiajs/vue3'
import CustomSvg from '@/Pages/Admin/Page/Templates/Partials/Svg.vue';

export default {

    components: {
        Header,
        Footer,
        JetNavLink,
        Head,
        CustomSvg
    },
    props: {
        menu_items: Object,
        page: Object,
        editable: Boolean,
        website: Object,
    },

    data() {
        return {}
    },
    methods: {
        itemsContains(n) {
            return this.page.page_options.indexOf(n) > -1
        },
        backExists() {
            if (typeof window !== "undefined") {
                return window.history.length > 1;
            }
            return false;
        },
        back() {
            if (typeof window !== "undefined") {
                window.history.back();
            }
        },
        isNumeric(num) {
            return !isNaN(num)
        },
        getImgWidth(product) {
            if (!this.isNumeric(this.infoHeight)) {
                return '100%';
            }
            if (product.size_y - this.infoHeight < product.size_x - this.infoWidth) {
                return this.infoWidth+'px';
            } else {
                return Math.floor(product.size_x / (product.size_y / this.infoHeight))+'px';
            }
        },
        getImgHeight(product) {
            if (!this.isNumeric(this.infoHeight)) {
                return 'auto;';
            }
            if (product.size_x - this.infoWidth < product.size_y - this.infoHeight) {
                return this.infoHeight+'px';
            } else {
                return Math.floor(product.size_y / (product.size_x / this.infoWidth))+'px';
            }
        },
    }
}
</script>

